.socials {
	text-align: center;

	.social {
		width: 33.333%;
		float: left;
		padding: 30px;
		cursor: pointer;
		transition: background-color $standard-transition;

		i {
			@include font_size(icon4x);
			display: block;
		}

		.social_text {
			@include font_size(md);
		}

		&:hover {
			.social_text {
				text-decoration: underline;
			}
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.panel_body {
		@include clearfix();
	}
}
