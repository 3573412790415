$stamp_padding: 20px;

.enduro_stamp {
	@include font_size(sm);
	position: fixed;
	top: $stamp_padding;
	right: $stamp_padding;
	background-color: $white;
	padding: 8px 10px 6px 15px;
	z-index: 5;
	border-radius: 8px;
	box-shadow: 0px 0px 6px rgba($black, .1), 0px 4px 8px rgba($black, .1);
	transition: background-color $standard-transition;

	> div {
		display: inline-block;
		vertical-align: middle;
	}

	svg {
		width: 30px;
		height: 30px;
		margin-left: 5px;
		margin-top: 1px;
	}

	&:hover {
		background-color: rgba(81, 203, 238, 1);
		color: #fff;

		.e_border {
			fill: #fff;
		}

		.e_e {
			fill: rgba(81, 203, 238, 1);
		}
	}

	@include media-breakpoint-down(sm) {
		top: auto;
		bottom: $stamp_padding;
	}
}