.main_menu {
	@include font-size(md);

	ul {
		padding-left: 0;

		li {
			list-style-type: none;
		}
	}

}