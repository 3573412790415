.btn {
	background: transparent;
	border: 1px solid rgba($black, .5);
	padding: 8px 20px 10px;
	font-weight: 300;
	cursor: pointer;
	transition: border-radius $standard-transition;
	text-decoration: none;

	.dark & {
		color: scheme(1);
		border-color: scheme(1);
	}

	&:hover {
		border-radius: 8px;
	}
}