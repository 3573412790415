.contentbox {
	position: relative;
	padding: 50px;
	background-color: scheme(1);
	display: inline-block;

	.contentbox_content {
		text-align: center;
	}

	@include media-breakpoint-down(lg) {
		padding: 30px;
	}

	@include media-breakpoint-down(xs) {
		width: 100%;
		padding: 20px;
		opacity: .8;
	}
}