// * ———————————————————————————————————————————————————————— * //
// * 	Text center
// * ———————————————————————————————————————————————————————— * //

// Check if grid breakpoints is defined(might be by bootstrap)
// if not, define it again
$grid-breakpoints: (
	xs: 0,
	sm: 544px,
	md: 768px,
	lg: 992px,
	xl: 1280px
) !default;

.text_center {
	text-align: center;
}

@each $breakpoint, $size in $grid-breakpoints {
	@include media-breakpoint-down(#{$breakpoint}) {
		.text_center_#{$breakpoint}-down {
			text-align: center;
		}
	}

	@include media-breakpoint-up(#{$breakpoint}) {
		.text_center_#{$breakpoint}-up {
			text-align: center;
		}
	}
}
